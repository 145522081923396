export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'Update Date',
        field: 'updateDate',
    },
    {
        headerName: 'Is Deleted',
        field: 'isDeleted',
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Course Section ID',
        field: 'courseSectionId',
        filter: false,
    },
    {
        headerName: 'Grading Period ID',
        field: 'gradingPeriodId',
        filter: false,
    },
    {
        headerName: 'Marking Period',
        field: 'markingPeriod',
        filter: false,
    },
    {
        headerName: 'Standard Category Name',
        field: 'standardCategoryName',
        filter: false,
    },
    {
        headerName: 'Standard Code',
        field: 'standardCode',
        filter: false,
    },
    {
        headerName: 'Standard Description',
        field: 'standardDescription',
        filter: false,
    },
    {
        headerName: 'Standard Progress Name',
        field: 'standardProgressName',
        filter: false,
    },
    {
        headerName: 'Student Course Grade Letter',
        field: 'studentCourseGradeLetter',
        filter: false,
    },
    {
        headerName: 'Student Grade Percentage',
        field: 'studentGradePercentage',
        filter: false,
    },
    {
        headerName: 'Credits',
        field: 'credits',
        filter: false,
    },
    {
        headerName: 'Final Exam',
        field: 'finalExam',
        filter: false,
    },
    {
        headerName: 'Active',
        field: 'active',
        filter: false,
    },
]
